import React, { useRef, useState } from 'react';
// import { Box, Flex, Text, Image, Button, Center } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
  serverTimestamp
} from "firebase/firestore";
import { db, storage } from '../db/firebase-config'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { v4 } from 'uuid'
import Button from './Button';

const ImageUploader = ({ ...props }) => {
  const [fileUrl, setUrl] = useState(props.defaultValue);
  const imageInputRef = useRef();

  const uploadFile = (file) => {
    if (file == null) return "";
    var url = file.name + v4();

    const imageRef = ref(storage, `${url}`)

    uploadBytes(imageRef, file).then((snapshot) => {
      // 업로드 되자마자 뜨게 만들기
      getDownloadURL(snapshot.ref).then((imageUrl) => {
        props.setUrl(imageUrl)
        setUrl(imageUrl)
      });
    })
  }

  const handleDrop = (acceptedFiles) => {
    // 파일을 선택하거나 드롭할 때 호출됩니다.
    uploadFile(acceptedFiles[0])
  };

  return (
    <div style={{width:'100%', height:"100%"}}>
      <Dropzone onDrop={acceptedFiles => handleDrop(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input ref={imageInputRef} {...getInputProps()} />
              {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
              <div style={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                {(fileUrl || props.defaultValue) && <img src={fileUrl ? fileUrl : props.defaultValue} style={{ cursor:'pointer', borderRadius: props.borderRadius ? props.borderRadius : "0px" , objectFit: props.fit ? 'scale-down' : 'cover', width: props.width ? props.width : '100%', height: props.height ? props.height : '100%' }} />}
                {(!fileUrl || fileUrl == "") && <div style={{ borderRadius:'1rem', backgroundColor:'#d9d9d9', width: props.width ? props.width : '100%', height: props.height ? props.height : '100%' }}/>}
                
                <button onClick={() => {
                  if (imageInputRef.current) { imageInputRef.current.onClick() }
                }
                }
                  style={{ cursor:'pointer', display: props.display ? props.display : '',  width: '100%', height: '100%', marginTop: "1rem", backgroundColor: 'white', border: '1px solid #5180F7', color: "#5180F7", padding: '0.5rem', fontSize: '1rem', borderRadius: '10px' }}>이미지 변경</button>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
};

export default ImageUploader;
