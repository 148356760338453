import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { heroBackground } from '../../../assets';
import { Button, TextField } from '../../../components';
import useResize from '../../../hooks/useResize';
import { getData } from '../../../db/function';

const S = {
  Background: styled.section`
    // position: absolute;
    top: 0;
    width: 100%;
    // height: 780px;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 50px;
    height: 100vh;
  `,
  Wrapper: styled.div`
    // width: 100%;
    height: 100%;
    max-width: 1180px;
    // padding-top: 100px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `,
  Main: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    padding: 20px;
    height: 100%;
  `,
  Name: styled.div`
  display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: ${props => props.width};
    padding: 20px;
  `,
  Title: styled.h1`
    ${props => props.theme.typography.title};
    color: #000;
    margin-bottom: 2rem;
    text-align: center;
    white-space: nowrap;
    font-size: ${props => props.fontSize};
    font-weight: bold;
    
  `,
  Description: styled.p`
    text-align: center;
    font-size: ${props => props.fontSize};
    color: ${props => props.theme.palette.black};
    margin-bottom: 2rem;
    line-height: 2.5rem;
    white-space: pre-wrap;
  `,
  Form: styled.form`
  display: flex;
  width: 100%;
  flex-direction: row;
  input {
    width: 100%;
  }
  button {
    margin-left: 0.5rem;
  }
`,
  Subscribe: styled.div`
// max-width: 480px;
margin-bottom: 5vh;
`,
  Message: styled.div`
${props => props.theme.typography.description};
font-size: 0.9rem;
color: #8c8c8c;
margin-bottom: 2rem;
text-align: center;
`,
  Slogan: styled.div`
// max-width: 480px;
font-size: ${props => props.fontSize};
color: ${props => props.color};
font-weight: bold;
text-align: center;
width: 100%;
padding: 10px;
`,
};

const Hero = () => {
  const windowSize = useResize();
  const [data, setData] = useState({});

  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const introData = await getData("intro");
    setData(introData)
  }

  return (
    <S.Background>
      <S.Main>
        <S.Title fontSize={windowSize.width > 1120 ? '4rem' : '2rem'}>
          {data.title}
        </S.Title>
        <S.Description fontSize={windowSize.width > 1120 ? '1.5rem' : '1rem'}>
          {data.description}
        </S.Description>
        <S.Subscribe>
          <S.Form>
            <TextField type="text" placeholder="Enter Your Email" />
            <Button fill="solid" type="submit">
            무료 상담신청
            </Button>
          </S.Form>
          <S.Message>
            No spam, notifications only about new products, updates and freebies.
            You can always unsubscribe.
          </S.Message>
        </S.Subscribe>

        <S.Slogan color={'black'} fontSize={windowSize.width > 1120 ? '2.5rem' : '1.5rem'}>
          {data.subtitle1}
        </S.Slogan>
        <S.Slogan color={'#5180F7'} fontSize={windowSize.width > 1120 ? '2.5rem' : '1.5rem'}>
          {data.subtitle2}
        </S.Slogan>

      </S.Main>
    </S.Background>
  );
};

export default Hero;
