import React, { useCallback, useEffect, useRef, useState } from "react";
import { getData, updateData } from "../../../db/function";
import ImageUploader from "../../../components/ImageUploader";
import { Footer } from "../../../components";
import styled from "styled-components";
import Hero from "./Hero";

const S = {
    Wrapper: styled.div`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
  `,
    Label: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    // text-align: center;
    // margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    width: 100%;
    font-size: ${props => props.fontSize}
    align-items: center;
  `,
    Title: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    // text-align: center;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: ${props => props.fontSize}
  `,
    Description: styled.p`
    ${props => props.theme.typography.description};
    color: ${props => props.theme.palette.black};
    // margin-bottom: 4rem;
    width: 100%;
    // text-align: center;
  `,
    List: styled.ul`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: ${props => props.direction};
          margin-bottom: 4rem;
          justify-content: center;
          align-items: center;
        `,
    ListItem: styled.div`
        //   width: 350px;
        //   height: 700px;
          box-shadow: 0 8px 16px 8px rgba(0, 0, 0, 0.05);
          border-radius: 2rem;
        //   padding: 20px;
          display: flex;
        //   justify-content: center;
        //   align-items: center;
          flex-direction: column;
          background-color: white;
          margin: 2%;
        //   min-height: 450px;
        `,
    ItemImage: styled.div`
          width: 80px;
          height: 80px;
          border-radius: 1.5rem;
          background: no-repeat center/cover url(${props => props.image});
          background-color: ${props => props.image ? 'white' : '#d9d9d9'};
          padding: 2rem;
          margin: 1rem;
          margin-left: 0;
        `,
    TextContainer: styled.div`
          padding-left: 1rem;
          padding-right: 1rem;
          width: 100%;
        //   height: 300px;
        `,
    ItemTitle: styled.h3`
          ${props => props.theme.typography.heading};
          color: #5180F7;
          margin-bottom: 0.75rem;
          font-weight: bold;
          text-align: center;
          width: 100%;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        `,
    ItemLabel: styled.p`
          ${props => props.theme.typography.caption};
          color: ${props => props.theme.palette.gray};
          font-weight: 400;
          margin-bottom: 1.5rem;
        `,
    ItemDesciption: styled.p`
          ${props => props.theme.typography.description};
          margin-bottom: 1.5rem;
          line-height: 2rem;
          white-space: pre-wrap;
        `,
    TextButton: styled.button`
          width: fit-content;
          padding: 0;
          ${props => props.theme.typography.textbutton};
          color: ${props => props.theme.palette.secondary};
          cursor: pointer;
        `,
    PortfolioTitle: styled.h3`
        ${props => props.theme.typography.heading};
        color: ${props => props.color};
        margin-bottom: 0.75rem;
        font-weight: bold;
        font-size: ${props => props.fontSize}
      `,
    PortfolioDesciption: styled.p`
      ${props => props.theme.typography.description};
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      color: ${props => props.color};
      font-size: ${props => props.fontSize}
      `,
};

const Admin = () => {

    const [INTRO_ITEMS, setIntroItems] = useState([]);
    const [SERVICES_ITEMS, setServiceItems] = useState([]);
    const [WORKS_ITEMS, setWorkItems] = useState([]);
    const [FEAURE_ITEMS, setPortfolio] = useState([]);
    const [PARTNERS_LOGO, setPartners] = useState([]);
    const [data, setData] = useState({});
    const [title, setTitle] = useState({});
    const [company, setCompany] = useState({});
    const items1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const imageInputRef = useRef();

    useEffect(() => {
        getDB();
    }, [])

    const addList = (type) => {
        let defaultValue = {};
        if (type == 'work') {
            defaultValue = {
                brand: '',
                description: '',
                image: '',
                label: '',
                title: ''
            }

            setWorkItems([...WORKS_ITEMS, defaultValue]);
        }

        if (type == 'portfolio') {
            defaultValue = {
                image: '',
                label: '',
                link: '',
                title: ''
            }

            setPortfolio([...FEAURE_ITEMS, defaultValue]);
        }

        if (type == 'partners') {
            defaultValue = "";
            setPartners([...PARTNERS_LOGO, defaultValue]);
        }
    }

    const delList = (type, index) => {
        if (window.confirm("삭제하시겠습니까?")) {
            let tempList = []
            if (type == 'work') {
                tempList = WORKS_ITEMS;
                tempList.splice(index, 1);
                setWorkItems(tempList);
            }
            else if (type == 'portfolio') {
                tempList = FEAURE_ITEMS;
                tempList.splice(index, 1);
                setPortfolio(tempList);
            }
            else if (type == 'partners') {
                tempList = PARTNERS_LOGO;
                tempList.splice(index, 1);
                setPartners(tempList);
            }
            forceUpdate();
        }

    }

    const getDB = async () => {
        const introData = await getData("service");
        setIntroItems(introData.intro_items)
        setServiceItems(introData.service_items)

        const portfolio = await getData("portfolio")
        setWorkItems(portfolio.works_items)
        setPortfolio(portfolio.portfolio_items)

        const introduce = await getData("introduce");
        setData(introduce)

        const title = await getData("title");
        setTitle(title)

        const company = await getData("company");
        setCompany(company)

        const partners = await getData("partners");
        setPartners(partners.logo);

        forceUpdate();

    }

    const inputChange = (type, index, id, value) => {

        if (type === 'service') {
            var service = SERVICES_ITEMS[index];
            SERVICES_ITEMS[index] = { ...service, [id]: value }
        }
        else if (type === 'introduce') {
            setData({ ...data, [id]: value })
        }
        else if (type === 'title') {
            setTitle({ ...title, [id]: value })
        }
        else if (type === 'company') {
            setCompany({ ...company, [id]: value })
        }
        else if (type === 'work') {
            var work = WORKS_ITEMS[index];
            WORKS_ITEMS[index] = { ...work, [id]: value };
        }
        else if (type === 'portfolio') {
            var portfolio = FEAURE_ITEMS[index];
            FEAURE_ITEMS[index] = { ...portfolio, [id]: value };
        }
        else if(type == 'partners') {
            PARTNERS_LOGO[index] = value;
        }
    }

    const saveDB = async (type) => {
        if (window.confirm("저장하시겠습니까?")) {
            if (type === 'service')
                await updateData(type, { intro_items: INTRO_ITEMS, service_items: SERVICES_ITEMS })
            else if (type === 'introduce')
                await updateData(type, data)
            else if (type === 'title')
                await updateData(type, title)
            else if (type === 'company')
                await updateData(type, company)
            else if (type === 'work' || type === 'portfolio')
                await updateData('portfolio', { works_items: WORKS_ITEMS, portfolio_items: FEAURE_ITEMS })
            else if (type == 'partners')
                await updateData('partners', { logo: PARTNERS_LOGO })

                window.alert('저장되었습니다.')
        }
    }

    function KakaoMap() {

        useEffect(() => {
            const script = document.createElement('script');
            script.type = "text/javascript"
            script.async = true;
            script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=049d8060396c1a04250a1e3ae2737d48&libraries=services";
            document.head.appendChild(script);

            script.onload = () => {
                const container = document.getElementById('map');
                const options = {
                    center: new window.kakao.maps.LatLng(37.5560249, 126.995384),
                    level: 3,
                };
                const map = new window.kakao.maps.Map(container, options);

                // 주소-좌표 변환 객체를 생성합니다
                var geocoder = new window.kakao.maps.services.Geocoder();

                geocoder.addressSearch(company.addr, function (result, status) {

                    // 정상적으로 검색이 완료됐으면 
                    if (status === window.kakao.maps.services.Status.OK) {

                        var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
                        console.log(coords)

                        map.setCenter(coords);

                        var imageSrc = require('../../../assets/marker.svg'); // 마커이미지의 주소입니다    
                        var imageSize = new window.kakao.maps.Size(46, 46); // 마커이미지의 크기입니다
                        var imageOption = { offset: new window.kakao.maps.Point(23, 23) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

                        // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
                        var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption)

                        const markerPosition = coords;//new window.kakao.maps.LatLng(37.5560249, 126.995384);
                        const marker = new window.kakao.maps.Marker({
                            position: markerPosition,
                            image: markerImage
                        });
                        marker.setMap(map);
                    };
                }, []);

            }
        })

        return <div id="map" style={{ width: '100%', height: '500px' }}></div>;
    }

    return (
        <div>
            {/* header */}
            <div style={{ width: "100%", height: "5rem", backgroundColor: "#43444E", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: "100%", height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ fontSize: '1.5rem', color: '#5180F7' }}>
                        Edit
                    </div>
                    <div style={{ margin: '20px', fontSize: '1.5rem', color: 'white' }}>|</div>
                    <a href="http://v-up.kr" style={{ fontSize: '1.5rem', color: 'white', cursor: 'pointer', textDecoration: 'none' }}>
                        v-up.kr ↗︎
                    </a>

                </div>

            </div>

            <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'row' }}>

                {/* navigate */}
                <div style={{ width: "300px", minWidth: '200px', backgroundColor: '#43444E', zIndex: 999 }}>
                    <div style={{ fontSize: '1.2rem', color: 'white', width: "100%", height: "5rem", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <a style={{textDecoration:'none', cursor:'pointer', color:'white',}} href="#about">서비스 소개</a>
                    </div>

                    <div style={{ fontSize: '1.2rem', color: 'white', width: "100%", height: "5rem", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <a  style={{textDecoration:'none', cursor:'pointer', color:'white',}} href="#portfolio">포트폴리오</a>
                    </div>

                    <div style={{ fontSize: '1.2rem', color: 'white', width: "100%", height: "5rem", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <a style={{textDecoration:'none', cursor:'pointer', color:'white',}} href="#contact">오시는길</a>
                    </div>

                </div>

                <div id="about" style={{ width: '100%', minWidth: "1200px" }}>
                    {/* intro */}
                    <Hero />


                    {/* service */}
                    <div style={{ padding: '2rem', backgroundColor: '#5180F7', margin: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        {/* <div style={{fontSize: '1.5rem', color: 'black', fontWeight:'bold', padding: "1rem"}}>서비스 관리</div> */}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {
                                INTRO_ITEMS.map(({ icon, name }, index) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ margin: '1vw', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                            <div style={{ borderRadius: 999, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#648EF8', width: '8vw', height: '8vw' }}>
                                                <img src={icon} style={{ width: '55%', height: 'auto' }} />
                                            </div>
                                            <div style={{ padding: '20px', fontWeight: 'bold', color: 'white', whiteSpace: 'pre-wrap', textAlign: 'center', fontSize: `2.2vh`, lineHeight: `1.8rem` }}>
                                                {name}
                                            </div>
                                        </div>

                                        <div style={{ margin: '30px', display: index < 4 ? '' : 'none', backgroundColor: 'white', opacity: '0.4', width: '1px', height: '60%' }} />
                                    </div>
                                ))}
                        </div>

                        {SERVICES_ITEMS.map((value, index) => (
                            <div style={{ backgroundColor: 'white', border: '1px solid #d9d9d9', maxWidth: '1200px', borderRadius: '1rem', width: "100%", padding: "1rem", margin: '1rem', display: 'flex', flexDirection: 'row' }} >
                                <div style={{ width: "40%", height: "auto" }}>
                                    <div style={{ width: '100%', height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem' }}>
                                        <ImageUploader borderRadius='20px' children="이미지 변경" defaultValue={value.image} setUrl={(value) => inputChange('service', index, 'image', value)} />
                                    </div>

                                </div>
                                <div style={{ width: "60%", alignItems: 'center', justifyContent: 'center', padding: "1rem" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <input onChange={(e) => inputChange('service', index, 'title', e.target.value)} defaultValue={value.title} style={{ border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                        <input onChange={(e) => inputChange('service', index, 'header', e.target.value)} defaultValue={value.header} style={{ border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                        <textarea onChange={(e) => inputChange('service', index, 'description', e.target.value)} defaultValue={value.description} style={{ fontFamily: 'sans-serif', border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "180px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div style={{ width: "50%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                            <button onClick={() => window.location.reload()} style={{ width: '50%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                            <button onClick={() => saveDB('service')} style={{ width: '50%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                        </div>

                    </div>

                    {/* works */}
                    <S.Wrapper id="portfolio">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: "20px" }}>

                            <S.Title fontSize={'3rem'}>
                                Portfolio
                                <img src={require('../../../assets/glass.png')} style={{ height: "5rem" }}></img>
                            </S.Title>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: "20px" }}>
                            <S.Label fontSize={'2.5rem'} style={{ flexDirection: 'row', display: 'flex' }}>
                                밸류업파트너스 성공사례
                                <div style={{ cursor: 'pointer' }} onClick={() => addList('work')}>
                                    <img src={require('../../../assets/+.png')} style={{ height: "5rem", width: "40px", height: "40px", margin: "0 10px" }}></img>
                                </div>
                            </S.Label>
                            <S.Description>
                            상담문의를 통해 더욱 많은 사례를 확인하실 수 있습니다.
                            </S.Description>
                        </div>
                    </S.Wrapper>

                    <div style={{ display: 'grid', columns: 3, width: "100%", gridTemplateColumns: '1fr 1fr 1fr', padding: '2rem', gap: 20 }}>
                        {WORKS_ITEMS.map((item, index) => (
                            <S.ListItem key={item.title}>
                                <div onClick={() => delList('work', index)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: "30px", height: "30px", backgroundColor: '#5180F7', borderRadius: "999px", alignSelf: 'flex-end', marginTop: "-15px", marginRight: "-15px" }}>
                                    <img src={require("../../../assets/x.png")} style={{ width: "16px", height: "16px" }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', width: "100%", paddingInline: '2rem' }}>

                                    {/* <S.ItemImage image={item.image} /> */}
                                    <div style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem' }}>
                                        <ImageUploader fit='cover' width='80px' height="80px" display="none" defaultValue={item.image} setUrl={(value) => inputChange('work', index, 'image', value)} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: "100%" }}>
                                        <input onChange={(e) => inputChange('work', index, 'brand', e.target.value)} defaultValue={item.brand} style={{ border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', padding: 20, marginBottom: '1.3rem' }} />
                                        <input onChange={(e) => inputChange('work', index, 'label', e.target.value)} defaultValue={item.label} style={{ fontWeight: 'bold', border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', padding: 20 }} />
                                    </div>
                                </div>
                                <S.TextContainer>
                                    <S.ItemTitle>
                                        <input onChange={(e) => inputChange('work', index, 'title', e.target.value)} defaultValue={item.title} style={{ fontWeight: 'bold', border: '1px solid #d9d9d9', textAlign: "center", color: "#5180F7", borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.4rem', margin: "0.5rem", padding: 20 }} />
                                    </S.ItemTitle>
                                    <div style={{ width: '100%', height: '1px', backgroundColor: "#dddddd" }} />
                                    <div style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <textarea onChange={(e) => inputChange('work', index, 'description', e.target.value)} defaultValue={item.description.replace(', ', '\n')} style={{ fontSize: '1.1rem', fontFamily: 'sans-serif', color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "150px", margin: "0rem", padding: 15 }} />
                                    </div>
                                    {/* <S.ItemDesciption>{item.description}</S.ItemDesciption> */}
                                    {/* <S.TextButton>Read more</S.TextButton> */}
                                </S.TextContainer>
                            </S.ListItem>
                        ))}
                    </div>

                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                        <button onClick={() => window.location.reload()} style={{ width: '25%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                        <button onClick={() => saveDB('work')} style={{ width: '25%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                    </div>

                    {/* portfolio */}
                    <S.Wrapper>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: "20px" }}>
                            <S.Label fontSize={'2.5rem'} style={{ flexDirection: 'row', display: 'flex' }}>
                                Project
                                <div style={{ cursor: 'pointer' }} onClick={() => addList('portfolio')}>
                                    <img src={require('../../../assets/+.png')} style={{ height: "5rem", width: "40px", height: "40px", margin: "0 10px" }}></img>
                                </div>
                            </S.Label>
                        </div>
                    </S.Wrapper>

                    <div style={{ display: 'grid', columns: 4, width: "100%", gridTemplateColumns: '1fr 1fr 1fr 1fr', padding: '2rem', gap: 20 }}>
                        {FEAURE_ITEMS.map((value, index) => (
                            <a style={{ textDecoration: "none" }}>
                                <div style={{ margin: "15px", padding: "0px", height: "auto", display: 'flex', flexDirection: 'column' }}>
                                    <div onClick={() => delList('portfolio', index)} style={{ cursor: 'pointer', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: "30px", height: "30px", backgroundColor: '#5180F7', borderRadius: "999px", alignSelf: 'flex-end', marginTop: "-15px", marginRight: "-15px" }}>
                                        <img src={require("../../../assets/x.png")} style={{ width: "16px", height: "16px" }} />
                                    </div>
                                    <div style={{ position: 'absolute', padding: "10px" }}>
                                        <S.PortfolioDesciption fontSize={'1rem'} color="white">{value.label}</S.PortfolioDesciption>
                                        <S.PortfolioTitle fontSize={'1.5rem'} color="white">{value.title}</S.PortfolioTitle>
                                    </div>
                                    {/* <img src={value.image} style={{ objectFit: 'cover', backgroundColor: '#d9d9d9', height: '50vh', width: "100%" }} /> */}
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <ImageUploader height='50vh' width="100%" display="none" defaultValue={value.image} setUrl={(value) => inputChange('portfolio', index, 'image', value)} />
                                    </div>
                                    <div>
                                        <input onChange={(e) => inputChange('portfolio', index, 'label', e.target.value)} defaultValue={value.label} style={{ color: "#5180F7", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.0rem', margin: "0.5rem", padding: 20 }} />
                                        <input onChange={(e) => inputChange('portfolio', index, 'title', e.target.value)} defaultValue={value.title} style={{ border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                        <input onChange={(e) => inputChange('portfolio', index, 'link', e.target.value)} defaultValue={value.link} style={{ border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />

                                    </div>

                                </div>
                            </a>
                        ))}

                    </div>


                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                        <button onClick={() => window.location.reload()} style={{ width: '25%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                        <button onClick={() => saveDB('portfolio')} style={{ width: '25%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                    </div>

                    {/* partners */}
                    <S.Wrapper>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: "20px" }}>
                            <S.Label style={{ flexDirection: 'row', display: 'flex' }}>
                                Partners
                                <div style={{ cursor: 'pointer' }} onClick={() => addList('partners')}>
                                    <img src={require('../../../assets/+.png')} style={{ height: "5rem", width: "40px", height: "40px", margin: "0 10px" }}></img>
                                </div>
                            </S.Label>
                        </div>
                    </S.Wrapper>

                    <div style={{ display: 'grid', width: "100%", gridTemplateColumns: '1fr 1fr 1fr 1fr', padding: '2rem', gap: 30 }}>
                        {PARTNERS_LOGO.map((value, index) => (
                            <div style={{ padding: "0px", height: "auto", display: 'flex', flexDirection: 'column' }}>
                                <div onClick={() => delList('partners', index)} style={{ cursor: 'pointer', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', width: "30px", height: "30px", backgroundColor: '#5180F7', borderRadius: "999px", alignSelf: 'flex-end', marginTop: "-15px", marginRight: "-15px" }}>
                                    <img src={require("../../../assets/x.png")} style={{ width: "16px", height: "16px" }} />
                                </div>
                                <div style={{ border: "1px solid #d9d9d9", width: "100%", height: "80px", display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem' }}>
                                        <ImageUploader fit="center" height="50px" display="none" defaultValue={value} setUrl={(value) => inputChange('partners', index, 'image', value)} />
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>

                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                        <button onClick={() => window.location.reload()} style={{ width: '25%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                        <button onClick={() => saveDB('partners')} style={{ width: '25%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                    </div>

                    {/* introduce */}
                    <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: "100%" }}>
                            <div style={{ width: "100%", textAlign: 'center', paddingTop: "50px", fontSize: '2.5rem', fontWeight: 'bold' }}>
                                VALUE UP PARTNERS
                            </div>
                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '40px' }}>
                                    <ImageUploader width="100%" defaultValue={data.image} setUrl={(value) => inputChange('introduce', -1, 'image', value)}/>
                                </div>
                                <div style={{ width: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', padding: '40px' }}>
                                    <div >
                                        <input onChange={(e) => inputChange('introduce', -1, 'division', e.target.value)} defaultValue={data.division} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />

                                        <input onChange={(e) => inputChange('introduce', -1, 'name_kr', e.target.value)} defaultValue={data.name_kr} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />

                                        <input onChange={(e) => inputChange('introduce', -1, 'name_en', e.target.value)} defaultValue={data.name_en} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />

                                        <textarea onChange={(e) => inputChange('introduce', -1, 'description', e.target.value)} defaultValue={data.description} style={{ fontFamily: 'sans-serif', color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "500px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                        <button onClick={() => window.location.reload()} style={{ width: '25%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                        <button onClick={() => saveDB('introduce')} style={{ width: '25%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                    </div>

                    {/* title */}
                    <div id="contact" style={{ width: "100%", height: "100vh", backgroundColor: "#5180F7", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                        <textarea onChange={(e) => inputChange('title', -1, 'title', e.target.value)} defaultValue={title.title} style={{ fontFamily: 'sans-serif', color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "90%", height: "200px", fontSize: '3.5rem', fontWeight: 'bold', margin: "2rem", padding: '2rem', textAlign: 'center' }} />
                        <textarea onChange={(e) => inputChange('title', -1, 'subtitle', e.target.value)} defaultValue={title.subtitle} style={{ fontFamily: 'sans-serif', color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "90%", height: "200px", fontSize: '2rem', margin: "2rem", padding: '2rem', textAlign: 'center' }} />

                        <div style={{ width: "50%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                            <button onClick={() => window.location.reload()} style={{ width: '50%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                            <button onClick={() => saveDB('title')} style={{ width: '50%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                        </div>
                    </div>

                    {/* map */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "100px", padding: '4rem' }}>
                        <div style={{ width: "100%" }}>
                            <S.Label fontSize={'2.5rem'}>
                                밸류업파트너스 본사
                            </S.Label>
                            <div style={{ paddingBottom: "20px", width: "100%" }}>
                                <div style={{ padding: "10px 20px", display: 'flex', flexDirection: 'row', fontSize: '1.2rem', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold', width: "50px" }}>Add.</div>
                                    <input onChange={(e) => inputChange('company', -1, 'addr', e.target.value)} defaultValue={company.addr} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                </div>
                                <div style={{ padding: "10px 20px", display: 'flex', flexDirection: 'row', fontSize: '1.2rem', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold', width: "50px" }}>Tel.</div>
                                    <input onChange={(e) => inputChange('company', -1, 'tel', e.target.value)} defaultValue={company.tel} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                </div>
                                <div style={{ padding: "10px 20px", display: 'flex', flexDirection: 'row', fontSize: '1.2rem', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold', width: "50px" }}>Fax.</div>
                                    <input onChange={(e) => inputChange('company', -1, 'fax', e.target.value)} defaultValue={company.fax} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                </div>
                                <div style={{ padding: "10px 20px", display: 'flex', flexDirection: 'row', fontSize: '1.2rem', alignItems: 'center' }}>
                                    <div style={{ fontWeight: 'bold', width: "50px" }}>Mail.</div>
                                    <input onChange={(e) => inputChange('company', -1, 'mail', e.target.value)} defaultValue={company.mail} style={{ color: "black", border: '1px solid #d9d9d9', borderRadius: '8px', width: "100%", height: "40px", fontSize: '1.2rem', margin: "0.5rem", padding: 20 }} />
                                </div>

                                <div style={{ marginBottom: "2rem", width: "100%" }} />
                                {/* <KakaoMap addr={company.addr}/> */}
                                <KakaoMap />
                            </div>
                            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'row', margin: '1rem' }}>
                                <button onClick={() => window.location.reload()} style={{ width: '25%', marginTop: "1rem", backgroundColor: '#d9d9d9', borderWidth: '0px', color: "black", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>취소</button>
                                <button onClick={() => saveDB('company')} style={{ width: '25%', marginTop: "1rem", backgroundColor: 'black', borderWidth: '0px', color: "white", padding: '1rem', fontSize: '1.2rem', borderRadius: '10px', margin: '0.5rem' }}>저장</button>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )
}
export default Admin;