// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAu7MsDtfus1ccKFn6tlU0gmO_iDgMqf3c",
  authDomain: "value-up-data.firebaseapp.com",
  projectId: "value-up-data",
  storageBucket: "value-up-data.appspot.com",
  messagingSenderId: "352093865561",
  appId: "1:352093865561:web:18fd3a7c478f70a25e1348",
  measurementId: "G-J561GE7J64"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const db = getFirestore(app);
export const storage = getStorage(app);