import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from './Button';
import TextField from './TextField';
import { Logo } from '../assets';
import useResize from '../hooks/useResize';
import { getData } from '../db/function';

const S = {
  Wrapper: styled.footer`
    width: 100%;
    // max-width: 1180px;
    margin: auto;
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    flex-wrap: wrap;
    // background-color: #EFF2F6;
  `,
  Label: styled.h2`
  ${props => props.theme.typography.subtitle};
  color: ${props => props.theme.palette.black};
  // text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: bold;
  max-width: 1180px;
  width: 100%;
  padding-left: 20px;
  font-size: ${props => props.fontSize}
`,
  Info: styled.div`
    // flex: 0 0 30%;
    // max-width: 30%;
    font-size: 0.8rem;
    // width: "100%";
    margin-right: 40px;
  `,
  Logo: styled.div`
    //${props => props.theme.typography.label};
    //color: ${props => props.theme.palette.darkgray};
    // display: inline-block;
    // font-weight: 900;
    // font-size: 1.5rem;
    // margin-bottom: 2rem;
  `,
  Text: styled.p`
    ${props => props.theme.typography.body};
    color: ${props => props.theme.palette.gray};
    text-align: center;
    font-size: 0.7rem;
    span {
      display: block;
      margin-bottom: 1.5rem;
    }
  `,
  Menu: styled.ul`
    // flex: 0 0 20%;
    // width: 20%;
    display: flex;
    flex-direction: column;
    padding: 20px;
  `,
  MenuTitle: styled.h4`
    ${props => props.theme.typography.label};
    color: black;
    margin-bottom: 1.0rem;
  `,
  MenuItem: styled.a`
    ${props => props.theme.typography.body};
    color: ${props => props.theme.palette.gray};
    display: inline-block;
    margin-bottom: 0.8rem;
    margin-right: 30px;
    font-size: 0.8rem;
    // cursor: pointer;
    // &:hover {
    //   opacity: 0.5;
    // }
  `,
  Subscribe: styled.div`
    flex: 0 0 30%;
    max-width: 30%;
    p {
      line-height: 1.5;
    }
  `,
  Form: styled.form`
    input {
      width: 60%;
    }
    margin-bottom: 1rem;
  `,
  Bottom: styled.div`
    width: 100%;
    color: ${props => props.theme.palette.gray};
    margin-top: 120px;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    border-top: 1px solid ${props => props.theme.palette.lightgray};
    span {
      ${props => props.theme.typography.caption};
    }
  `,
};

const Location = () => {
  const windowSize = useResize();

  const [company, setCompany] = useState({});

  function KakaoMap() {

    useEffect(() => {
      const script = document.createElement('script');
      script.type = "text/javascript"
      script.async = true;
      script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=049d8060396c1a04250a1e3ae2737d48&libraries=services";
      document.head.appendChild(script);

      script.onload = () => {
        const container = document.getElementById('map');
        const options = {
          center: new window.kakao.maps.LatLng(37.5560249, 126.995384),
          level: 3,
        };
        const map = new window.kakao.maps.Map(container, options);

        // 주소-좌표 변환 객체를 생성합니다
        var geocoder = new window.kakao.maps.services.Geocoder();

        geocoder.addressSearch(company.addr, function (result, status) {

          // 정상적으로 검색이 완료됐으면 
          if (status === window.kakao.maps.services.Status.OK) {

            var coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
            console.log(coords)

            map.setCenter(coords);

            var imageSrc = require('../assets/marker.svg'); // 마커이미지의 주소입니다    
            var imageSize = new window.kakao.maps.Size(46, 46); // 마커이미지의 크기입니다
            var imageOption = { offset: new window.kakao.maps.Point(23, 23) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

            // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
            var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption)

            const markerPosition = coords;//new window.kakao.maps.LatLng(37.5560249, 126.995384);
            const marker = new window.kakao.maps.Marker({
              position: markerPosition,
              image: markerImage
            });
            marker.setMap(map);
          };
        }, []);

      }
    })

    return <div id="map" style={{ width: '100%', height: '500px' }}></div>;
  }


  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const data = await getData("company");
    setCompany(data)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "100px" }}>
      <div style={{ maxWidth: "1180px", width: "100%" }}>
        <S.Label fontSize={windowSize.width > 1120 ? '2.5rem' : '1.5rem'}>
          밸류업파트너스 본사
        </S.Label>
        <div style={{ paddingBottom: "20px", width: "100%" }}>
          <div style={{ padding: "20px", display: 'flex', flexDirection: 'row', fontSize: `${windowSize.width}` > 1120 ? '1.2rem' : '1.0rem' }}>
            <div style={{ fontWeight: 'bold' }}>Add.</div><div>&nbsp; {company.addr} {company.addr_detail}</div>
          </div>
          <div style={{ padding: "20px", display: 'flex', flexDirection: 'row', fontSize: `${windowSize.width}` > 1120 ? '1.2rem' : '1.0rem' }}>
            <div style={{ fontWeight: 'bold' }}>Tel.</div><div>&nbsp; {company.tel}</div>
          </div>
          <div style={{ padding: "20px", display: 'flex', flexDirection: 'row', fontSize: `${windowSize.width}` > 1120 ? '1.2rem' : '1.0rem' }}>
            <div style={{ fontWeight: 'bold' }}>Fax.</div><div>&nbsp; {company.fax}</div>
          </div>
          <div style={{ padding: "20px", display: 'flex', flexDirection: 'row', fontSize: `${windowSize.width}` > 1120 ? '1.2rem' : '1.0rem' }}>
            <div style={{ fontWeight: 'bold' }}>Mail.</div><div>&nbsp; {company.mail}
            </div></div>

          <div style={{ marginBottom: "2rem", width: "100%" }} />
          {/* <KakaoMap addr={company.addr}/> */}
          <KakaoMap />
        </div>
      </div>
    </div>
  );
}

export default Location;
