import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "./firebase-config"

export const getData = async(id) => {
    const docRef = doc(db, "Data", id);
    const _doc = await getDoc(docRef);

    //console.log("Data", _doc.data());

    return _doc.data();
}

export const updateData = async(id, data) => {
    const docRef = doc(db, "Data", id);
    await updateDoc(docRef, data);
}