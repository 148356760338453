import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { featureImage } from '../../../assets';
import { useScrollClipPath } from '../../../hooks';
import useResize from '../../../hooks/useResize';
import { getData } from '../../../db/function';

const S = {
  Wrapper: styled.section`
    width: 100%;
    max-width: 1180px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  TextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 580px;
  `,
  Label: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    // text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    max-width: 1180px;
    width: 100%;
    font-size: ${props => props.fontSize}
  `,
  Title: styled.h2`
  ${props => props.theme.typography.subtitle};
  color: ${props => props.theme.palette.black};
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;

`,
  Description: styled.p`
    ${props => props.theme.typography.description};
    color: ${props => props.theme.palette.black};
    margin-bottom: 2rem;
  `,
  List: styled.ul`
    width: fit-content;
    margin-bottom: 2rem;
  `,
  ListItem: styled.p`
    ${props => props.theme.typography.description};
    padding: 1rem 1rem 1rem 0;
    border-bottom: 1px solid ${props => props.theme.palette.lightgray};
    span {
      color: ${props => props.theme.palette.secondary};
    }
  `,
  TextButton: styled.button`
    width: fit-content;
    ${props => props.theme.typography.textbutton};
    color: ${props => props.theme.palette.secondary};
    cursor: pointer;
  `,
  Image: styled.div`
    width: 580px;
    height: 580px;
    background: no-repeat center/cover url();
  `,
  ItemTitle: styled.h3`
  ${props => props.theme.typography.heading};
  color: ${props => props.color};
  margin-bottom: 0.75rem;
  font-weight: bold;
  font-size: ${props => props.fontSize}
`,
ItemDesciption: styled.p`
${props => props.theme.typography.description};
margin-top: 1rem;
margin-bottom: 0.5rem;
color: ${props => props.color};
font-size: ${props => props.fontSize}
`,
};

const Feature = () => {
  const animatedImage = useScrollClipPath();
  const windowSize = useResize();

  const [FEAURE_ITEMS, setPortfolio] = useState([]);

  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const portfolio = await getData("portfolio");
    setPortfolio(portfolio.portfolio_items)
  }


  return (
    <div>

    <S.Wrapper>
      <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: "20px"}}>
      <S.Label fontSize={windowSize.width > 1120 ? '3rem' : '1.5rem'}>
        Project
      </S.Label>
      </div>

    </S.Wrapper>
    
    <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:"100%", 
        padding: '10px'}}>

      <div style={{
        display: 'grid', 
        width:"100%",
        gridTemplateColumns: `${windowSize.width}` > 1200 ? "1fr 1fr 1fr 1fr" : `${windowSize.width}` > 800 ? "1fr 1fr 1fr" : "1fr 1fr", 
        gridTemplateRows: `${windowSize.width}` > 1200 ? `${100 / parseInt(((FEAURE_ITEMS.length - 1) / 4) + 1)}% ${100 / parseInt(((FEAURE_ITEMS.length - 1) / 4) + 1)}%` :
         `${windowSize.width}` > 800 ? `${100 / parseInt(((FEAURE_ITEMS.length - 1) / 3) + 1)}% ${100 / parseInt(((FEAURE_ITEMS.length - 1) / 3) + 1)}%` : 
         `${100 / parseInt(((FEAURE_ITEMS.length - 1) / 2) + 1)}% ${100 / parseInt(((FEAURE_ITEMS.length - 1) / 2) + 1)}% ${100 / parseInt(((FEAURE_ITEMS.length - 1)/ 2) + 1)}% ${100 / parseInt(((FEAURE_ITEMS.length - 1) / 2) + 1)}%`,  
        // backgroundColor: 'yellow', 
        maxWidth: "1180px"}}>

        {FEAURE_ITEMS.map((value, index) => (
          <a href={value.link} style={{textDecoration: "none", cursor:'pointer'}}>
                <div style={{margin: "15px", padding: "0px", height: "auto"}}>
                <div style={{position:'absolute',padding: "10px"}}>
                  <S.ItemDesciption fontSize={windowSize.width > 800 ? '1.0rem' : '0.8rem'} color="white">{value.label}</S.ItemDesciption>
                    <S.ItemTitle fontSize={windowSize.width > 800 ? '1.5rem' : '1.2rem'} color="white">{value.title}</S.ItemTitle>
                  </div>
                  <img src={value.image} style={{objectFit:'cover', backgroundColor:'#d9d9d9', height: `${windowSize.width}` > 800 ? "50vh" : "40vh", width: "100%"}}/>
                  <div>
                  <S.ItemDesciption fontSize={windowSize.width > 800 ? '1.0rem' : '0.8rem'} color="#5180F7">{value.label}</S.ItemDesciption>
                    <S.ItemTitle fontSize={windowSize.width > 800 ? '1.5rem' : '1.2rem'} color="black">{value.title}</S.ItemTitle>
                  </div>

                </div>
                </a>
        ))}

      </div>
      </div>
      
      </div>
  );
};

export default Feature;
