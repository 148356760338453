import React, { useEffect, useState, useCallback } from 'react';
import { Header, Footer } from '../../components';
import {
  Hero,
  Services,
  Feature,
  Figure,
  Works,
  Contact,
  Banner,
} from './components';
import styled from 'styled-components';
import Button from '../../components/Button';
import useResize from '../../hooks/useResize';
import Location from '../../components/Location';

const S = {
  Wrapper: styled.div`
  position: fixed;
  top: 0; left: 0;
  // height: 100px;
  width: 100%;
    // width: 100%;
    // position: sticky;
    // height: 100%;
    // top: 0;
    z-index: 106;
    transition: all 0.2s ease-in-out;
    background-color: white;
    padding-top: 20px;
    box-shadow: ${props => props.isScroll ? '0 16px 8px rgba(0, 0, 0, 0.03)' : 'none'};
  `,
  Header: styled.header`
    width: 100%;
    // max-width: 1180px;
    justify-content: center;
    // background-color: red;
    // text-align: center;
    // margin: auto;
    transition: all 0.2s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: row;
    // align-items: center;
  `,
  Logo: styled.span`
    color: ${({ isScroll, theme }) =>
      isScroll ? theme.palette.black : theme.palette.white};
    font-weight: 900;
    font-size: 1.5rem;
    flex: 0 0 25%;
    max-width: 25%;
  `,
  Navigation: styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: center;
  `,
  NavigationItem: styled.a`
    color: ${props => props.theme.palette.white};
    color: ${({ isScroll, theme }) =>
      isScroll ? theme.palette.black : theme.palette.white};
    margin: 0 1rem;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  `,
  ButtonWrapper: styled.div`
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    justify-content: flex-end;
  `,
};

const NAVIGATION_ITEMS = ['Home', 'About', 'Services', 'Blog', 'Contact'];

const Home = () => {
  const [isScroll, setIsScroll] = useState(false);
  const windowSize = useResize();
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = useCallback(() => {
    setScrollY(window.scrollY * 100 / document.getElementById('main').scrollHeight)

    if (window.pageYOffset > 0) {
      setIsScroll(true);
    }
    if (window.pageYOffset === 0) {
      setIsScroll(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousewheel', handleScroll);
    return () => {
      window.removeEventListener('mousewheel', handleScroll);
    };
  }, [handleScroll]);

  // useEffect(() => {
  //   console.log(scrollY)
  // }, [scrollY]);

  const handleKakao = () => {
    const script = document.createElement("script");
    script.src = `
    function gtag_report_conversion(url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
          'send_to': 'AW-11363084794/0jfxCNzE7-oYEPrTq6oq',
          'event_callback': callback
      });
      return false;
    }
    `
    script.async = true;
    document.body.appendChild(script);
  }

  return (
    <div id="main">
      <div id='about'>

          <S.Wrapper isScroll={isScroll}>
      <S.Header isScroll={isScroll}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection: `${windowSize.width}` > 800 ? 'row' : 'column', width: '100%', maxWidth: "1180px", height:'100%'}}>
        <div>
        <img 
        src={require('../../assets/logo-main.png')}
        style={{
          height: "70px"
          // maxWidth:"50%"
          }}/>
          </div>
          <div style={{display:'flex', flexDirection:'row', paddingInline: '20px'}}>
            <a onClick={() => setScrollY((n) => {return (0)})} href="#about" style={{textDecoration:'none', cursor:'pointer', color:'black', margin: "10px", borderWidth: 0, color: scrollY < 33 ? "#5180F7" : "black"}}>서비스 소개</a>
            <a onClick={() => setScrollY((n) => {return (33.2)})} href="#portfolio" style={{textDecoration:'none', cursor:'pointer', color:'black', margin: "10px", borderWidth: 0, color: scrollY > 33 && scrollY < 74.8 ? "#5180F7" : "black"}}>포트폴리오</a>
            <a onClick={() => setScrollY((n) => {return (74.81)})} href="#contact" style={{textDecoration:'none', cursor:'pointer', color:'black', margin: "10px", borderWidth: 0, color: scrollY > 74.8 ? "#5180F7" : "black"}}>오시는길</a>

          </div>
        </div>
      </S.Header>
    </S.Wrapper>
      {/* <Header id="#about"/> */}
      <Hero />
      <Services />
      </div>
      <div id="portfolio">
      <Works/>
      <Feature />
      <Banner />
      <Contact />
      </div>

      <div id="contact">
      <Figure/>
      <Location/>
      <Footer />
      </div>

      <div style={{zIndex: 10000, position:'fixed', display:'flex', flexDirection:'column', bottom: 0, right: 0, padding: "30px"}}>
          <a href="#about" style={{ marginBottom: "10px"}}>
            <img src={require('../../assets/top.png')} style={{width: `${windowSize.width}` > 800 ? '80px' : '50px', width: `${windowSize.width}` > 800 ? '80px' : '50px'}}/>
          </a>

          <a href='https://open.kakao.com/me/vuppartners' style={{ marginBottom: "10px"}}>
            <div onClick={() => handleKakao()}>
            <img src={require('../../assets/kakao.png')} style={{width: `${windowSize.width}` > 800 ? '80px' : '50px', width: `${windowSize.width}` > 800 ? '80px' : '50px'}}/>
            </div>
          </a>
      </div>
      
    </div>
  );
};

export default Home;
