import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useScrollCount } from '../../../hooks';
import useResize from '../../../hooks/useResize';
import { getData } from '../../../db/function';

const S = {
  Background: styled.section`
    width: 100%;
    height: 100vh;
    background-color: #5180F7;
    align-items: center;
    justify-content: center;
    display: flex;
  `,
  Wrapper: styled.div`
    // width: 100%;
    // height: 100%;
    // max-width: 1180px;

  `,
  List: styled.ul`
    display: flex;
  `,
  ListItem: styled.li`
    width: 100%;
    // padding: 0 2rem;
    text-align: center;
    &:nth-child(2) {
      border: 2px solid ${(props) => props.theme.palette.white};
      border-top: none;
      border-bottom: none;
    }
  `,
  Number: styled.span`
    ${(props) => props.theme.typography.subtitle};
    color: black;
    font-size: 3rem;
    margin-bottom: 1rem;
  `,
  Unit: styled.span`
    ${(props) => props.theme.typography.subtitle};
    color: black;
    font-size: 3rem;
    margin-bottom: 1rem;
  `,
  Title: styled.h3`
    ${(props) => props.theme.typography.description};
    margin: 1rem 0;
    color: white;
    white-space: pre-wrap;
    font-weight: bold;
    font-size: ${(props) => props.fontSize};
  `,
  Description: styled.p`
    ${(props) => props.theme.typography.description};
    color: white;
    white-space: pre-wrap;
    line-height: 2;
    font-size: ${(props) => props.fontSize};
  `,
};

const FIGURE_ITEMS = [
  {
    title: 'Active Mercharts',
    number: 630,
    unit: '',
    description: 'Ipsum faucibus vitae aliquet nec ullamcorper sit amet risus.',
  },
  {
    title: 'Reveneus in 2018',
    number: 124,
    unit: '$',
    description:
      'Nisi scelerisque eu ultrices vitae auctor eu augue ut lectus.',
  },
  {
    title: 'Downloads',
    number: 92,
    unit: '',
    description:
      'Porttitor rhoncus dolor purus non enim praesent elementum facilisis.',
  },
];

const Figure = () => {
  const windowSize = useResize();
  const countItem = {
    0: useScrollCount(23),
    1: useScrollCount(3),
    2: useScrollCount(87),
  };

  const [data, setData] = useState({});

  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const title = await getData("title");
    setData(title)
  }

  return (
    <S.Background>
      <S.Wrapper>
        <div style={{textAlign: 'center'}}>
          <S.Title fontSize={windowSize.width > 1120 ? '4rem' : '2rem'}>
          {data.title}
          </S.Title>
          <S.Description fontSize={windowSize.width > 1120 ? '1.5rem' : '1.2rem'}>
          {data.subtitle}
          </S.Description>

        </div>

      </S.Wrapper>
    </S.Background>
  );
};

export default Figure;
