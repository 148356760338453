import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Button from './Button';
import useResize from '../hooks/useResize';

const S = {
  Wrapper: styled.div`
    width: 100%;
    // position: sticky;
    height: 100%;
    top: 0;
    z-index: 106g;
    transition: all 0.2s ease-in-out;
    background-color: white;
    padding-top: 20px;
    box-shadow: ${props =>
      props.isScroll ? '0 0 16px 8px rgba(0, 0, 0, 0.03)' : 'none'};
  `,
  Header: styled.header`
    width: 100%;
    // max-width: 1180px;
    justify-content: center;
    // background-color: red;
    // text-align: center;
    // margin: auto;
    transition: all 0.2s ease-in-out;
    height: 100%;
    display: flex;
    flex-direction: row;
    // align-items: center;
  `,
  Logo: styled.span`
    color: ${({ isScroll, theme }) =>
      isScroll ? theme.palette.black : theme.palette.white};
    font-weight: 900;
    font-size: 1.5rem;
    flex: 0 0 25%;
    max-width: 25%;
  `,
  Navigation: styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    justify-content: center;
  `,
  NavigationItem: styled.a`
    color: ${props => props.theme.palette.white};
    color: ${({ isScroll, theme }) =>
      isScroll ? theme.palette.black : theme.palette.white};
    margin: 0 1rem;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  `,
  ButtonWrapper: styled.div`
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    justify-content: flex-end;
  `,
};

const NAVIGATION_ITEMS = ['Home', 'About', 'Services', 'Blog', 'Contact'];

const Header = () => {
  const [isScroll, setIsScroll] = useState(false);
  const windowSize = useResize();

  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 0) {
      setIsScroll(true);
    }
    if (window.pageYOffset === 0) {
      setIsScroll(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('mousewheel', handleScroll);
    return () => {
      window.removeEventListener('mousewheel', handleScroll);
    };
  }, [handleScroll]);

  return (
    <S.Wrapper isScroll={isScroll}>
      <S.Header isScroll={isScroll}>
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection: `${windowSize.width}` > 800 ? 'row' : 'column', width: '100%', maxWidth: "1180px"}}>
        <div>
        <img 
        src={require('../assets/logo-main.png')}
        style={{
          height: "70px"
          // maxWidth:"50%"
          }}/>
          </div>
          <div style={{display:'flex', flexDirection:'row'}}>
            <Button style={{color:'black', margin: "10px", borderWidth: 0}}>서비스 소개</Button>
            <Button style={{color:'black', margin: "10px", borderWidth: 0}}>포트폴리오</Button>
            <Button style={{color:'black', margin: "10px", borderWidth: 0}}>오시는길</Button>

          </div>
        </div>
      </S.Header>
    </S.Wrapper>
  );
};

export default Header;
