import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Image } from '../../../assets';
import { Button } from '../../../components';
import { useScrollFadeIn } from '../../../hooks';
import useResize from '../../../hooks/useResize';
import Slide from '../../../components/Slider';
import { getData } from '../../../db/function';

const S = {
  Wrapper: styled.div`
    width: 100%;
    // max-width: 1180px;
    // margin: auto;
    // padding: 50px 20px;
    // display: flex;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: red;
    //height: 100vh;
  `,
  Label: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    // text-align: center;
    // margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    max-width: 1180px;
    width: 100%;
    font-size: ${props => props.fontSize}
  `,
  Title: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    // text-align: center;
    max-width: 1180px;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: ${props => props.fontSize}
  `,
  Description: styled.p`
    ${props => props.theme.typography.description};
    color: ${props => props.theme.palette.black};
    // margin-bottom: 4rem;
    max-width: 1180px;
    width: 100%;
    // text-align: center;
  `,
  List: styled.ul`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => props.direction};
    margin-bottom: 4rem;
    justify-content: center;
    align-items: center;
  `,
  ListItem: styled.li`
    width: 350px;
    height: 700px;
    // box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.03);
    border-radius: 0.5rem;
    padding: 10px;
  `,
  ItemImage: styled.div`
    width: 100%;
    height: 350px;
    border-radius: 0.5rem 0.5rem 0 0;
    background: no-repeat center/cover url(${props => props.image});
  `,
  TextContainer: styled.div`
    padding: 2rem;
  `,
  ItemTitle: styled.h3`
    ${props => props.theme.typography.heading};
    color: ${props => props.theme.palette.black};
    margin-bottom: 0.75rem;
    font-weight: bold;
  `,
  ItemLabel: styled.p`
    ${props => props.theme.typography.caption};
    color: ${props => props.theme.palette.gray};
    font-weight: 400;
    margin-bottom: 1.5rem;
  `,
  ItemDesciption: styled.p`
    ${props => props.theme.typography.description};
    margin-bottom: 1.5rem;
  `,
  TextButton: styled.button`
    width: fit-content;
    padding: 0;
    ${props => props.theme.typography.textbutton};
    color: ${props => props.theme.palette.secondary};
    cursor: pointer;
  `,
};

// const WORKS_ITEMS = [
//   {
//     image: require('../../../assets/Logo3.png'),
//     brand: '엔제이인터내셔널',
//     title: '마케팅 목표 달성',
//     label: '이나나',
//     description:
//       ['사업계획서 컨설팅을 통한 정부지원사업 선정', '데이터바우처 AI분야 선정으로 바우처 6천만 원 지원'],
//   },
//   {
//     image: require('../../../assets/Logo1.png'),
//     brand: '피앤피코리아',
//     title: '마케팅 목표 달성',
//     label: '이인수',
//     description: ['사업계획서 컨설팅을 통한 정부지원사업 선정', '네트워크형 R&BD 지원사업 선정으로 연구개발비 6억 원 지원'],
//   },
//   {
//     image: require('../../../assets/Logo2.png'),
//     brand: 'KD덴탈',
//     title: '마케팅 목표 달성',
//     label: '엄태준',
//     description: 
//     ['사업계획서 컨설팅을 통한 정부지원사업 선정', '소상공인진흥공단 희망리턴패키지 경영개선 지원사업 선정으로 사업비 1천만 원 지원'],
//   },
//   {
//     image: require('../../../assets/Logo4.png'),
//     brand: '팜베이스플러스',
//     title: '마케팅 목표 달성',
//     label: '황희철',
//     description: ['IR 자료 작성 및 피칭 컨설팅을 통한 투자유치 준비완료', '투자자 연결로 투자자의 피드백 수령 및 투자자 네트워크 형성'],
//   },
//   {
//     image: require('../../../assets/Logo5.png'),
//     brand: '카페22',
//     title: '마케팅 목표 달성',
//     label: '백진욱',
//     description:
//       ['사업계획서 컨설팅을 통한 정부지원사업 선정', '소상공인진흥공단 희망리턴패키지 경영개선 지원사업 선정으로 사업비 1천 5백만 원 지원'],
//   },
// ];

const Works = () => {
  const windowSize = useResize();
  const animatedItem = {
    0: useScrollFadeIn('left', 1),
    1: useScrollFadeIn('left', 1, 0.2),
    2: useScrollFadeIn('left', 1, 0.4),
    3: useScrollFadeIn('left', 1, 0.6),
    4: useScrollFadeIn('left', 1, 0.8),
  };

  const [WORKS_ITEMS, setPortfolio] = useState([]);

  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const works = await getData("portfolio");
    setPortfolio(works.works_items)
  }

  return (
    <S.Wrapper>
      {/* <S.Label>Our Recent Works</S.Label> */}
      <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: "20px"}}>
      <S.Title fontSize={windowSize.width > 1120 ? '3rem' : '2rem'}>
      Portfolio
      <img src={require('../../../assets/glass.png')} style={{height: "5rem"}}></img>
      </S.Title>
      </div>
      <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', padding: "20px"}}>
      <S.Label fontSize={windowSize.width > 1120 ? '2.5rem' : '1.5rem'}>
      밸류업파트너스 성공사례
      </S.Label>
      <S.Description>
      상담문의를 통해 더욱 많은 사례를 확인하실 수 있습니다.
      </S.Description>
      </div>
      <Slide item={WORKS_ITEMS}>

      </Slide>
      {/* <Button fill="outline">More Works</Button> */}
    </S.Wrapper>
  );
};

export default Works;
