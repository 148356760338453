import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, TextField } from '../../../components';
import { Profile } from '../../../assets';
import { useScrollFadeIn } from '../../../hooks';
import useResize from '../../../hooks/useResize';
import { getData } from '../../../db/function';

const S = {
  Wrapper: styled.section`
    width: 100%;
    // max-width: 1180px;
    // margin: auto;
    padding: 50px 0px;
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: center;
    align-items: center;
  `,
  Image: styled.div`
    width: ${props => props.width};
    height: 650px;
    background: no-repeat center url(${props => props.image});
    `,
  TextWrapper: styled.div`
    // box-sizing: border-box;
    width: ${props => props.width};
    // display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  Label: styled.p`
    display: inline-block;
    ${props => props.theme.typography.label};
    color: ${props => props.theme.palette.primary};
    margin-bottom: 1rem;
  `,
  Title: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    // margin-bottom: 3rem;
    text-align: left;
    font-size: 2.0rem;
  `,
  Description: styled.p`
    ${props => props.theme.typography.description};
    color: ${props => props.theme.palette.black};
    margin-bottom: 2rem;
    white-space: pre-wrap;
    text-align: left;
    line-height: 2.5;
    font-size: 1.0rem;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    input {
      margin-bottom: 1rem;
      width: 70%;
    }
    button {
      width: 70%;
    }
  `,
};

const Contact = () => {
  const windowSize = useResize();
  const animatedItem = {
    0: useScrollFadeIn('up', 1, 0),
    1: useScrollFadeIn('up', 1, 0.2),
    2: useScrollFadeIn('up', 1, 0.3),
    3: useScrollFadeIn('up', 1, 0.4),
  };

  const [data, setData] = useState({});

  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const introduce = await getData("introduce");
    setData(introduce)
  }

  return (
    <div style={{width: "100%", display:'flex', alignItems:'center', justifyContent:'center'}}>
    <div style={{ width: "100%", maxWidth:'1180px'}}>
      <div style={{width:"100%", textAlign:'center', paddingTop: "50px", fontSize:'2.5rem', fontWeight:'bold'}}>
      VALUE UP PARTNERS
      </div>
    <S.Wrapper {...animatedItem[1]} direction={windowSize.width > 1000? 'row' : 'column'}>
      <S.Image image={Profile} width={windowSize.width > 1000? '45%' : '100%'}/>
      <S.TextWrapper width={windowSize.width > 1000? '45%' : '100%'} style={{alignItems: 'center', justifyContent:'center', display:'flex', padding : '40px'}}>
        <div >
        {/* <S.Label {...animatedItem[0]}>Contact us</S.Label> */}
        <S.Label>{data.division}</S.Label>
        <S.Title {...animatedItem[1]} style={{letterSpacing: "0.8rem"}}>
        {data.name_kr}
        </S.Title>
        <div style={{fontSize:'1.2rem', alignItems: 'center', fontWeight:'bold', marginBottom: "50px", marginTop:"1rem" }}>
        {data.name_en}
        </div>
        <S.Description {...animatedItem[2]}>
        {data.description}
        </S.Description>
        </div>
      </S.TextWrapper>
    </S.Wrapper>
    </div>
    </div>
  );
};

export default Contact;
