import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useScrollFadeIn } from '../../../hooks';
import useResize from '../../../hooks/useResize';
import { Image01, Image02, Image03, Num01, Num02, Num03 } from '../../../assets';
import { getData } from '../../../db/function';

const S = {
  Wrapper: styled.section`
    width: 100%;
    // max-width: 1180px;
    // margin: auto;
    padding: 80px 0px 80px 0px;
    // margin-top: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #5180F7;
  `,
  Label: styled.p`
    display: inline-block;
    ${props => props.theme.typography.label};
    color: ${props => props.theme.palette.primary};
    margin-bottom: 3rem;
  `,
  Title: styled.h2`
    ${props => props.theme.typography.subtitle};
    color: ${props => props.theme.palette.black};
    text-align: center;
    margin-bottom: 4rem;
  `,
  ItemWrapper: styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  `,
  ItemBox: styled.li`
    // width: 380px;
    // width: 100%;
    // height: 700px;
    // padding: 3rem 2rem;
    // text-align: center;
    max-width: 1080px;
    background-color: ${props => props.theme.palette.white};
    display: flex;
    align-items: center;
    flex-direction: ${props => props.direction};
    // box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.03);
    border-radius: 1.5rem;
    margin: 20px 40px 20px 40px;
    padding: 60px 40px;
  `,
  ItemTitle: styled.h3`
    ${props => props.theme.typography.heading};
    color: ${props => props.theme.palette.black};
    margin-bottom: 1rem;
  `,
  ItemHeader: styled.h4`
  ${props => props.theme.typography.subheading};
  color: ${props => props.theme.palette.black};
  margin-bottom: 1rem;
  font-size: 1.1rem;
`,
  ItemImage: styled.div`
width: ${props => props.width};
height: 100%;
margin: 20px;
justify-content: center;
align-items: center;
`,
  ItemDescription: styled.p`
    ${props => props.theme.typography.description};
    // margin-bottom: 1.5rem;
    white-space: pre-wrap;
  `,
  ItemButton: styled.button`
    ${props => props.theme.typography.textbutton};
    color: ${props => props.theme.palette.secondary};
    margin-top: auto;
    cursor: pointer;
  `,
};

const Services = () => {
  const windowSize = useResize();
  const animatedItem = {
    0: useScrollFadeIn('up', 1, 0),
    1: useScrollFadeIn('up', 1, 0.2),
    2: useScrollFadeIn('up', 1, 0.3),
  };

  const [INTRO_ITEMS, setIntroItems] = useState([]);
  const [SERVICES_ITEMS, setServiceItems] = useState([]);

  useEffect(() => {
    getDB();
  }, [])

  const getDB = async () => {
    const introData = await getData("service");
    setIntroItems(introData.intro_items)
    setServiceItems(introData.service_items)
  }

  return (
    <S.Wrapper>
      <div style={{ display: 'flex', flexDirection: `${windowSize.width > 800 ? 'row' : 'column'}` }}>
        {
          INTRO_ITEMS.map(({ icon, name }, index) => (
            <div style={{ display: 'flex', flexDirection: `${windowSize.width > 800 ? 'row' : 'column'}`, alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ margin: '1vw', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: `${windowSize.width > 800 ? 'column' : 'row'}` }}>
                <div style={{ borderRadius: 999, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#648EF8', width: `${windowSize.width > 800 ? '10vw' : '20vw'}`, height: `${windowSize.width > 800 ? '10vw' : '20vw'}` }}>
                  <img src={icon} style={{ width: '55%', height: 'auto' }} />
                </div>
                <div style={{ padding: '20px', fontWeight: 'bold', color: 'white', whiteSpace: 'pre-wrap', textAlign: 'center', fontSize: `2.2vh`, lineHeight: `1.8rem` }}>
                  {name}
                </div>
              </div>

              <div style={{ margin: '30px', display: index < 4 ? '' : 'none', backgroundColor: 'white', opacity: '0.4', width: `${windowSize.width > 800 ? '1px' : '90%'}`, height: `${windowSize.width > 800 ? '60%' : '1px'}` }} />
            </div>
          ))
        }


      </div>
      <S.ItemWrapper direction={windowSize.width > 800 ? 'row' : 'column'}>
        {SERVICES_ITEMS.map((item, index) => (
          <div style={{ margin: '1rem 2rem', alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'white', maxWidth: "1080px", borderRadius: "1.5rem", padding: "1.5rem", flexDirection: `${windowSize.width > 800 ? 'row' : 'column'}` }}>
            <div style={{ width: "60%" }}>
              <img style={{ width: "100%" }} src={item.image} />
            </div>
            <div style={{ width: "100%", padding: "20px", flexDirection: "column", textAlign: `${windowSize.width > 800 ? 'left' : 'center'}` }}>
              <div style={{ fontSize: `${windowSize.width > 800 ? '2rem' : '1.5rem'}`, fontWeight: "bold", padding: "10px" }}>{item.title}</div>
              <div style={{ fontSize: `${windowSize.width > 800 ? '1.5rem' : '1.rem'}`, fontWeight: "bold", padding: "10px" }}>{item.header}</div>
              <div style={{ fontSize: `${windowSize.width > 800 ? '1.2rem' : '1rem'}`, lineHeight: "1.8rem", padding: "10px", marginTop: "10px", whiteSpace: 'pre-wrap' }}>{item.description}</div>
            </div>

          </div>
        ))}
      </S.ItemWrapper>
    </S.Wrapper>
    // </div>
  );
};

export default Services;
