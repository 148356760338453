import React, { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { useScrollFadeIn } from '../../../hooks';
import { Partners1, Partners2 } from '../../../assets'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import useResize from '../../../hooks/useResize';
import styled, { keyframes } from "styled-components";
import { getData } from '../../../db/function';

const Contain = styled.div`
    // margin:100px;
    position:relative;
    display: flex;
    flexDirection: row;
`

const rotateText = keyframes`
    0%{
        transform: translate(0px, 0);
    }
    100%{
        transform: translate(-2500px, 0);
    }
`


const Circle = styled.div`
    // width: 100%;
    padding: 10px;
    // height:200px;
    // position:absolute;
    z-index:99;
    left:0px;
    top:0px;
    display:flex;
    aling-items:center;
    animation: ${rotateText} 60s linear infinite;
    background-color:
`


const CircleText = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    left:100px;
`

const S = {
  Wrapper: styled.section`
    maxWidth: 1000px;
    padding: 100px 0;
    // background-color: ${props => props.theme.palette.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow-x: hidden;
  `,
  Label: styled.p`
    display: inline-block;
    ${props => props.theme.typography.label};
    color: ${props => props.theme.palette.primary};
    margin-bottom: 1rem;
  `,
  Title: styled.h2`
    ${props => props.theme.typography.subtitle};se
    color: ${props => props.theme.palette.black};
    margin-bottom: 2rem;
    text-align: center;
    font-weight: bold;
  `,
};

const Banner = () => {
  const animatedItem = {
    0: useScrollFadeIn('up', 1, 0),
    1: useScrollFadeIn('up', 1, 0.2),
    2: useScrollFadeIn('up', 1, 0.3),
  };
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);
  const windowSize = useResize();

  const [PARTNERS_LOGO, setPartners] = useState([]);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  useEffect(() => {
    getBanner()
  })

  const getBanner = async() => {
    const partners = await getData("partners");
    setPartners(partners.logo);
  }

  const handleClick =
    (id) =>
      ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id);

        setSelected((currentSelected) =>
          itemSelected
            ? currentSelected.filter((el) => el !== id)
            : currentSelected.concat(id)
        );
      };

  const items1 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const items2 = [10, 11, 12, 13, 14, 15, 16, 17, 18];

  return (
    <S.Wrapper {...animatedItem[1]}>
      {/* <S.Label {...animatedItem[0]}>Get Started</S.Label> */}
      <S.Title>
        Partners
      </S.Title>

      <Contain>
        <Circle>
          {PARTNERS_LOGO.map((item, i) => {
            return (
              <div key={i}
                style={{
                  transform: `translate(${i}px, 0px)`,
                  transformOrigin: "0 100px",
                  margin: "0px 10px"
                }}
              >
                <div style={{ width: "100%", height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', border: '1px solid #d9d9d9' }}>
                  <img src={item} style={{ width: "auto", height: '100px', padding: "20px" }} />
                </div>
              </div>
            )
          })}
        </Circle>
        <Circle>
          {PARTNERS_LOGO.map((item, i) => {
            return (
              <div key={i}
                style={{
                  transform: `translate(${i}px, 0px)`,
                  transformOrigin: "0 100px",
                  margin: "0px 10px"
                }}
              >
                <div style={{ width: "100%", height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', border: '1px solid #d9d9d9' }}>
                  <img src={item} style={{ width: "auto", height: '100px', padding: "20px" }} />
                </div>
              </div>
            )
          })}
        </Circle>
        <Circle>
          {PARTNERS_LOGO.map((item, i) => {
            return (
              <div key={i}
                style={{
                  transform: `translate(${i}px, 0px)`,
                  transformOrigin: "0 100px",
                  margin: "0px 10px"
                }}
              >
                <div style={{ width: "100%", height: "100%", alignItems: 'center', justifyContent: 'center', display: 'flex', border: '1px solid #d9d9d9' }}>
                  <img src={item} style={{ width: "auto", height: '100px', padding: "20px" }} />
                </div>
              </div>
            )
          })}
        </Circle>
      </Contain>
      {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} style={{backgroundColor: 'red'}}>
        <div style={{display:'flex', padding: "10px", alignItems:'center', justifyContent:'center', width: windowSize.width > 1200 ? '1095px' : `${windowSize.width - 105}px`, height:"100%", backgroundColor:"white"}}>
          <img src={require('../../../assets/img5.png')} style={{width: "100%"}}/>
        </div>
        <div style={{display:'flex', padding: "10px", alignItems:'center', justifyContent:'center', width: windowSize.width > 1200 ? '1095px' : `${windowSize.width - 105}px`, height:"100", backgroundColor:"white"}}>
          <img src={require('../../../assets/img6.png')} style={{width: "100%"}}/>
        </div>
    </ScrollMenu> */}
    </S.Wrapper>
  );

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <div style={{ cursor: 'pointer', padding: "20px", marginLeft: "-20px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        {'<'}
      </div>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
      <div style={{ cursor: 'pointer', padding: "20px", marginRight: "-20px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} disabled={isLastItemVisible} onClick={() => scrollNext()}>
        {'>'}
      </div>
    );
  }

};

export default Banner;
