import React, { useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import styled from 'styled-components';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useResize from '../hooks/useResize';

const S = {
    Wrapper: styled.div`
          width: 100%;
          // max-width: 1180px;
          // margin: auto;
          padding: 120px 0;
          // display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `,
    Label: styled.p`
          display: inline-block;
          ${props => props.theme.typography.label};
          color: ${props => props.theme.palette.primary};
          margin-bottom: 1rem;
        `,
    Title: styled.h2`
          ${props => props.theme.typography.subtitle};
          color: ${props => props.theme.palette.black};
          text-align: center;
          margin-bottom: 1rem;
          font-weight: bold;
        `,
    Description: styled.p`
          ${props => props.theme.typography.description};
          color: ${props => props.theme.palette.black};
          margin-bottom: 4rem;
          text-align: center;
        `,
    List: styled.ul`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: ${props => props.direction};
          margin-bottom: 4rem;
          justify-content: center;
          align-items: center;
        `,
    ListItem: styled.div`
        //   width: 350px;
        //   height: 700px;
          box-shadow: 0 8px 16px 8px rgba(0, 0, 0, 0.05);
          border-radius: 2rem;
        //   padding: 20px;
          display: flex;
        //   justify-content: center;
        //   align-items: center;
          flex-direction: column;
          background-color: white;
          margin: 2%;
          min-height: 450px;
        `,
    ItemImage: styled.image`
          width: 80px;
          height: 80px;
          object-fit: cover;
          //border-radius: 1.5rem;
          //background: no-repeat cover url(${props => props.image});
          //background-color: red;
        `,
    TextContainer: styled.div`
          //padding: 1rem;
          width: 100%;
          height: 300px;
        `,
    ItemTitle: styled.h3`
          ${props => props.theme.typography.heading};
          color: #5180F7;
          margin-bottom: 0.75rem;
          font-weight: bold;
          text-align: center;
          width: 100%;
          padding: 10px;
        `,
    ItemLabel: styled.p`
          ${props => props.theme.typography.caption};
          color: ${props => props.theme.palette.gray};
          font-weight: 400;
          margin-bottom: 1.5rem;
        `,
    ItemDesciption: styled.p`
          ${props => props.theme.typography.description};
          margin-bottom: 1.5rem;
          line-height: 2rem;
          white-space: pre-wrap;
        `,
    TextButton: styled.button`
          width: fit-content;
          padding: 0;
          ${props => props.theme.typography.textbutton};
          color: ${props => props.theme.palette.secondary};
          cursor: pointer;
        `,
};

const Slide = ({ item }) => {
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);
    const windowSize = useResize();

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    const settings = {
        // dots: true,
        infinite: true,
        slidesToShow: windowSize.width > 1000 ? windowSize.width > 1200 ? 4 : 3 : 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />
    };

    return (
        <div style={{ overflowX: 'hidden' }}>
            <Slider {...settings}>
                {item.map((value, index) => (
                    <Card
                        item={value}
                        onClick={handleClick(value.id)}
                        selected={isItemSelected(value.id)}
                    />
                ))}
            </Slider>
        </div>
    );

    function Card({ onClick, item }) {
        const visibility = React.useContext(VisibilityContext);

        return (
            <div
                onClick={() => onClick(visibility)}
                style={{
                    //   width: '100%',
                    // backgroundColor: "red",
                    margin: windowSize.width > 1000 ? "0 -50% 0 50%" : "0",
                    padding: windowSize.width > 1000 ? "40px 10px" : "20px 10px",
                }}
                tabIndex={0}
            >
                <S.ListItem key={item.title}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: "100%", padding: "40px" }}>

                        <img src={item.image} style={{width:'80px', height:'80px', objectFit:'scale-down'}} />
                        <div>
                            <div style={{ fontSize: '1.1rem', padding: "10px 20px" }}>
                                {item.brand}
                            </div>
                            <div style={{ fontWeight: 'bold', fontSize: '1.1rem', padding: "10px 20px" }}>
                                {item.label}
                            </div>
                        </div>
                    </div>
                    <S.TextContainer>
                        <S.ItemTitle>{item.title}</S.ItemTitle>
                        <div style={{ width: '100%', height: '1px', backgroundColor: "#dddddd" }} />
                        <div style={{ padding: "40px" }}>
                            <div>{item.description.split(', ').map((value, index) => (
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div style={{ padding: '7px' }}>•</div>
                                    <div style={{ lineHeight: '2rem', display: 'block' }}>{value}</div>
                                </div>
                            ))}</div>

                        </div>
                        {/* <S.ItemDesciption>{item.description}</S.ItemDesciption> */}
                        {/* <S.TextButton>Read more</S.TextButton> */}
                    </S.TextContainer>
                </S.ListItem>
                {/* <div className="card">
          <div>{title}</div>
          <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
          <div>selected: {JSON.stringify(!!selected)}</div>
        </div>
        <div
          style={{
            height: '200px',
          }}
        /> */}
            </div>
        );
    }
};

export default Slide;
